import { itemService } from '@/config'
function choosefileEnding (type) { // TODO find better way to solve this
  type = type.split(';')[0]
  switch (type) {
    case 'text/csv':
      return 'csv'
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx'
    case 'application/zip':
      return 'zip'
    case 'application/json':
      return 'json'
    case 'application/ld+json':
      return 'jsonld'
    default:
      return 'txt'
  }
}

async function allPromisesAndProgress (promises, progressCallback) {
  let counter = 0
  progressCallback(0)
  promises.forEach((promis) => {
    promis.then(() => {
      counter++
      progressCallback((counter * 100) / promises.length)
    })
  })
  return Promise.all(promises)
}

function replaceItemTypeWithHumanReadableText (type) {
  return (this.$store.state.optionsItemTypes.find((itemType) => itemType.value === type) || {}).text
}

function renameStatusToGerman (status, addText = false) {
  switch (status) {
    case 'draft':
      return ((addText) ? 'Neue Items' : 'Entwurf')
    case 'revisioned':
      return 'Überarbeitet' + ((addText) ? 'e Items' : '')
    case 'deferred':
      return 'Zurückgestellt' + ((addText) ? 'e Items' : '')
    case 'toRevise':
      return ((addText) ? 'Items ' : '') + 'zu überarbeiten'
    default:
      return 'Veröffentlicht' + ((addText) ? 'e Items' : '')
  }
}

function renameHelpPages (filename) {
  switch (filename.toLowerCase()) {
    case 'itembasket':
      return 'Item-Korb und Prüfungserzeugung'
    case 'itemcreation':
      return 'Item-Erzeugung und -Bearbeitung'
    case 'itemimportexport':
      return 'Item Import & Export'
    case 'itemlist':
      return 'Item-Liste'
    case 'itemreview':
      return 'Item-Begutachtung'
    case 'learningoutcomes':
      return 'Learning Outcomes'
    default:
      return ''
  }
}

function useCorrectProtocol (uri) {
  if (window.location.hostname === 'localhost' && !itemService.uri.startsWith('http:'))
    return uri.replace('http://', 'https://')
  if (window.location.protocol === 'https:' && uri.startsWith('http://'))
    return uri.replace('http://', 'https://')
  else if (window.location.protocol === 'http:' && uri.startsWith('https://'))
    return uri.replace('https://', 'http://')
  else
    return uri
}

async function delay (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function isItemUri (uri) {
  return uri.includes('/item/')
}

export { choosefileEnding, allPromisesAndProgress, replaceItemTypeWithHumanReadableText, renameStatusToGerman, renameHelpPages, useCorrectProtocol, delay, isItemUri }
