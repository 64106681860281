<template>
  <b-navbar toggleable="md" type="dark" variant="dark" fixed="top">

    <b-navbar-nav class="navbar-content">
      <!-- Left Sidebar Opener -->
      <b-button v-b-toggle.sidebar-1 class="mr-2 text-white" variant="flat">
        <font-awesome-icon icon="bars" />
      </b-button>

      <b-navbar-brand :to="(isEmpty(user)) ? '/' : '/projects'">
      <img src="@/assets/logo_round.png" width="40rem"/>&nbsp;
      <img src="@/assets/logo_small.png" width="100rem"/>
      </b-navbar-brand>

      <b-breadcrumb v-if="!isEmpty(breadcrumbItems)" :items="breadcrumbItems" class="d-none d-sm-flex"></b-breadcrumb>
    </b-navbar-nav>

    <b-navbar-nav class="ml-auto navbar-content">

      <b-button class="mr-2" id="basketButton" v-b-tooltip.hover title="Item-Korb" v-if="$route.name !== 'landing' && $route.name !== 'about' && $route.name !== 'projects' && $route.name !== 'help' && $route.name !== 'specialHelp'" variant="secondary" :to="'/projects/' + $route.params.projectID +  '/itembasket'" :disabled="($store.state.item.itemBasket.length <= 0)">
        <font-awesome-icon icon="shopping-basket" /> {{$store.state.item.itemBasket.length}}
      </b-button>

      <!-- Tutorial Opener -->
      <b-button v-b-toggle.sidebar-2 class="mr-2 text-white" variant="flat">
        <font-awesome-icon icon="question-circle" />
      </b-button>

      <information-menu class="mr-2" />

      <auth-menu class="d-none d-sm-block mr-2" />
    </b-navbar-nav>

  </b-navbar>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { renameStatusToGerman, renameHelpPages } from '@/common'
import authMenu from './authMenu'
import informationMenu from './informationMenu'

export default {
  name: 'navbar',
  components: { authMenu, informationMenu },
  computed: {
    user () {
      return this.$store.state.user
    },
    breadcrumbItems () { // TODO replicates somehow routes.js.... think about better way to implement it
      const name = this.$route.name
      const toReturn = []
      if (this.$route.path.includes('/projects'))
        toReturn.push({ text: 'Projekte', to: '/projects' })
      if (this.$route.path.includes('/projects/'))
        if (this.$store.getters['project/getCurrentProject']) toReturn.push({ text: this.$store.getters['project/getCurrentProject'].title, to: '/projects/' + this.$route.params.projectID })
      if (name === 'learningOutcomes')
        toReturn.push({ text: 'Learning Outcomes' })
      if (name === 'newItem')
        toReturn.push({ text: 'Neues Item' })
      if (name === 'itemList')
        toReturn.push({ text: 'Item Liste' })
      if (name === 'displayItem')
        toReturn.push({ text: 'Item ' + this.$route.params.id })
      if (name === 'review')
        toReturn.push({ text: 'Items begutachten' })
      if (name === 'reviewDetail') {
        toReturn.push({ text: 'Items begutachten', to: '/projects/' + this.$route.params.projectID + '/review' })
        toReturn.push({ text: renameStatusToGerman(this.$route.query.status, true) })
      }
      if (name === 'itemBasket')
        toReturn.push({ text: 'Item-Korb' })
      if (name === 'uploadItems')
        toReturn.push({ text: 'Import' })
      if (name === 'exam')
        toReturn.push({ text: 'Prüfung erzeugen' })
      if (name === 'ontodia')
        toReturn.push({ text: 'Itemanalyse' })
      if (name === 'linking')
        toReturn.push({ text: 'Verlinken' })
      if (name === 'logs')
        toReturn.push({ text: 'Ereignisverlauf' })
      if (name === 'about')
        toReturn.push({ text: 'Über EAs.LiT' })
      if (name === 'help')
        toReturn.push({ text: 'Hilfe' })
      if (name === 'specialHelp') {
        toReturn.push({ text: 'Hilfe', to: '/help' })
        toReturn.push({ text: 'Hilfe zu ' + renameHelpPages(this.$route.params.site.toLowerCase()) })
      }
      return toReturn
    }
  },
  methods: {
    isEmpty
  }
}
</script>

<style scoped>
  .breadcrumb {
    background-color: rgba(72, 78, 84, 0);
    margin-top: 0;
    margin-bottom: 0;
    padding: .5rem .5rem .5rem 0.2rem;
  }
  .breadcrumb-item.active > span {
    color: rgb(150, 150, 150);
  }
  .breadcrumb-item > a , .breadcrumb-item::before {
    color: rgb(255, 255, 255) !important;
  }
  #basketButton.margin {
    margin-right: 1rem;
  }
  .navbar-brand {
    margin-right: 0.5rem;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    padding: 0 0 0 0;
  }
  .navbar-brand > img:last-child {
    padding-bottom: 5px;
  }
  .navbar-content {
    flex-direction: row;
  }
</style>
