import ky from 'ky'
import isEmpty from 'lodash/isEmpty'
import { useCorrectProtocol } from '@/common'
const { itemService } = require('../config')
// const { sparqlEndpoint } = require('../config')

export default {
  async read (id) {
    try {
      return await ky.get(`${itemService.uri}/topic/${id}`).json()
    } catch (e) {
      console.log(e)
      return undefined
    }
  },

  async readAll () {
    try {
      const result = await ky.get(`${itemService.uri}/topic/*`).json()
      return result['@graph'] ? result['@graph'] : [result]
    } catch (e) {
      if (!isEmpty(e.response) && e.response.status !== 404)
        console.log(e)
      return []
    }
  },

  async delete (uri) {
    try {
      await ky.delete(useCorrectProtocol(uri))
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  },

  async write (data) {
    const uri = `${itemService.uri}/topic`
    const method = 'POST'

    const contentType = 'application/json'
    try {
      const toReturn = await ky(useCorrectProtocol(uri), {
        method,
        json: data,
        headers: { Accept: 'application/ld+json', 'Content-Type': contentType }
      }).json()
      return toReturn
    } catch (e) {
      console.error(e)
      if (!isEmpty(e.error) && !isEmpty(e.error.message))
        return false
      else {
        return false
      }
    }
  }

  // async getProjectInfos (projectUri) { // TODO too slow
  //   const dataGraph = `${itemService.uri.replace('https', 'http')}/data`
  //   const query = `prefix eal:   <http://tech4comp/eal/>
  //     select (COUNT(distinct ?item) AS ?itemQuantity) (COUNT(distinct ?lo) AS ?loQuantity)
  //     WHERE {
  //       GRAPH <${dataGraph}> {
  //         optional {
  //           ?item eal:task ?x .
  //           ?item eal:hasProject <${projectUri}> .
  //         }
  //         optional {
  //           ?lo a eal:LearningOutcome .
  //           ?lo eal:hasProject <${projectUri}> .
  //         }
  //       }
  //     }`
  //
  //   let response = await request({
  //     uri: sparqlEndpoint.uri,
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/sparql-query', 'Accept': 'application/json' },
  //     body: query,
  //     json: false
  //   })
  //
  //   response = JSON.parse(response)
  //
  //   const toReturn = {}
  //   response.head.vars.forEach((variable) => { toReturn[variable] = Number(response.results.bindings[0][variable].value) })
  //   return toReturn
  // }
}
