<template>
  <div class="authMenu">
    <div v-if="mode === 'normal'">
      <b-button v-if="!isEmpty($auth) && isEmpty(user)" variant="light" @click="$auth.signIn()">Login <font-awesome-icon icon="sign-in-alt"/></b-button>
      <b-nav-item-dropdown v-else-if="!isEmpty(user)" right text="Right align" class="py-0" menu-class="shadow-sm">
        <template slot="button-content">
          <b-avatar size="1.4rem">
            <jazzicon :address="user.preferred_username" :diameter="25" />
          </b-avatar>
          <span class="ml-1"> {{ user.name || user.preferred_username}}</span>
        </template>
        <b-dropdown-item href="#" @click="showModal = true">Profil</b-dropdown-item>
        <b-dropdown-item href="#" @click="signOut()">Ausloggen</b-dropdown-item>
      </b-nav-item-dropdown>
    </div>

    <div v-else-if="mode === 'list-item'">
      <div v-if="!isEmpty($auth) && isEmpty(user)">
        <b-button v-if="!isEmpty($auth) && isEmpty(user)" variant="block" @click="$auth.signIn()">Login<font-awesome-icon icon="sign-in-alt"/></b-button>
      </div>
      <div v-else-if="!isEmpty(user)" class="nav-item-sidebar" @click="showModal = true">
        <div class="nav-icon">
          <b-avatar size="1.4rem">
            <jazzicon :address="user.preferred_username" :diameter="25" />
          </b-avatar>
        </div>
        <span class="link--label">
        {{ user.name || user.preferred_username}}
        </span>
      </div>
    </div>

    <b-modal v-if="!isEmpty(user)" v-model="showModal" centered title="Ihr Profil" hide-footer>
      <b-container fluid>

        <b-row no-gutters align-v="center">
          <b-col cols="5" id="leftModalColumn">
            <b-avatar size="10rem">
              <jazzicon :address="user.preferred_username" :diameter="165" />
            </b-avatar>
          </b-col>
          <b-col id="rightModalColumn">
            <h2>{{user.name || user.preferred_username}}</h2>
            <hr>
            <strong>Nutzername:</strong> {{user.preferred_username}}<br/>
            <strong>Login-Zeit:</strong> {{(new Date(user.auth_time * 1000)).toLocaleString('de-DE')}}<br/>
          </b-col>
        </b-row>

        <b-row no-gutters align-v="center" class="text-center">
          <b-col>
            <hr>
            <b-button href="https://api.learning-layers.eu/" target="_blank">Profil bearbeiten</b-button>
          </b-col>
          <b-col>
            <hr>
            <b-button @click="signOut()">Ausloggen</b-button>
          </b-col>
        </b-row>

        <b-row no-gutters align-v="center" class="text-center">
          <b-col>
            <hr>
            Powered by <a href="https://api.learning-layers.eu/" target="_blank">Learning Layers</a>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: 'normal'
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    isEmpty,
    signOut () {
      this.$auth.signOut()
      this.showModal = false
    }
  }
}
</script>

<style>
  #rightModalColumn {
    padding: 1rem;
  }
  #leftModalColumn {
    border-right: 1px solid lightgrey;
  }
</style>
