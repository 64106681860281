import Vue from 'vue'
import { VuePlugin } from 'vuera'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import BootstrapVue from 'bootstrap-vue' // See https://bootstrap-vue.js.org/docs/ for bundle optimization
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VeeValidate, { Validator } from 'vee-validate'
import de from 'vee-validate/dist/locale/de'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core' // See https://www.npmjs.com/package/@fortawesome/vue-fontawesome#recommended for bundle optimization
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import isEmpty from 'lodash/isEmpty'
import Authentication from '@/auth'
import Jazzicon from 'vue-jazzicon'
import { oAuthProvider, demoMode } from '@/config'
import titleMixin from './mixins/titleMixin'

Vue.mixin(titleMixin)

Vue.component('jazzicon', Jazzicon)

Vue.config.productionTip = false

Vue.use(VuePlugin)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(VeeValidate, { inject: true, fieldsBagName: 'veeFields' })
Validator.localize('de', de)
Validator.extend('notEqualTo', {
  getMessage: (field, otherField) => 'Das Feld ' + field + ' hat den gleich Wert wie ' + otherField + '.',
  validate: (value, [otherValue]) => value != otherValue //eslint-disable-line
}, { hasTarget: true })
Validator.extend('minLength', {
  getMessage: () => 'Zu wenige Antwortelement.',
  validate: (value, [minLength]) => (Array.isArray(value)) ? value.length >= minLength : null //eslint-disable-line
})
Validator.extend('minPoints', {
  getMessage: (field, [minPoints]) => 'Die Punktzahl muss mindestens ' + minPoints + ' sein.',
  validate: (value, [minPoints]) => value.map((answer) => Number.parseInt(answer.points)).reduce((a, b) => a + b, 0) >= minPoints //eslint-disable-line
})
Validator.extend('sequenceStepsStartsAt', {
  getMessage: (field, [startCount]) => 'Die Anordungssequenz beginnt nicht bei ' + startCount,
  validate: (value, [startCount]) => value.find((answer) => Number.parseInt(answer.sequenceStep) === Number.parseInt(startCount)) !== undefined
})
Validator.extend('sequenceStepsRising', {
  getMessage: () => 'Die Anordungssequenz ist unterbrochen.',
  validate: (value) => value.map((answer) => Number.parseInt(answer.sequenceStep)).sort().every((el, index) => el === index + 1)
})

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

router.beforeEach((to, from, next) => {
  if (to.params.projectID && isEmpty(store.state.item.itemBasket))
    store.commit('item/loadItemBasketFromLocalstorage', to.params.projectID)
  if (to.params.projectID === undefined && !isEmpty(store.state.item.itemBasket))
    store.commit('item/replaceItemBasket', [])
  next()
})

if (!isEmpty(oAuthProvider.authority)) {
  Authentication.useRouter(router)
  Vue.prototype.$auth = Authentication // NOTE avaliable in the vue app through this.$auth
  Authentication.startup().then(ok => {
    if (ok) {
      startVue()
    }
  })
} else {
  console.warn('Starting without authentication, login and logging capabilities')
  Vue.prototype.$auth = null // NOTE avaliable in the vue app through this.$auth
  startVue()
}

Vue.prototype.$demoMode = demoMode

function startVue () {
  new Vue({
    router,
    store,
    render: h => h(App),
    async created () {
      const html = document.documentElement // returns the html tag
      html.setAttribute('lang', 'de')
      if (Authentication.isAuthenticated) {
        store.commit('setUser', Authentication.userProfile)
        if (this.$route.name === 'landing') // redirect if on landing page
          this.$router.push({ path: '/projects' })
      }
      const showTutorial = localStorage.getItem('showTutorial')
      if ((showTutorial === 'true') || (showTutorial === null))
        this.$store.commit('setTutorialState', true)
      else if ((showTutorial === 'false')) {
        this.$store.commit('setTutorialState', false)
      }
      await Promise.all([
        this.$store.dispatch('fillItemTypes'),
        this.$store.dispatch('fillKnowledgeDimensions'),
        this.$store.dispatch('fillPerformanceLevels'),
        this.$store.dispatch('project/fillProjects'),
        this.$store.dispatch('fillTopics'),
        this.$store.dispatch('fillLearningOutcomes')
      ])
    }
  }).$mount('#app')
}
