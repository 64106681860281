import ky from 'ky'
import isEmpty from 'lodash/isEmpty'
import { useCorrectProtocol } from '@/common'
const { itemService } = require('../config')
const { sparqlEndpoint } = require('../config')

export default {
  async read (id) {
    try {
      return await ky.get(`${itemService.uri}/project/${id}`).json()
    } catch (e) {
      console.log(e)
      return undefined
    }
  },

  async readAll () {
    try {
      const result = await ky.get(`${itemService.uri}/project/*`).json()
      return result['@graph'] ? result['@graph'] : [result]
    } catch (e) {
      if (!isEmpty(e.response) && e.response.status !== 404)
        console.log(e)
      return []
    }
  },

  async delete (uri) {
    try {
      await ky.delete(useCorrectProtocol(uri))
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  },

  async write (data) {
    const uri = (data['@id']) ? data['@id'] : `${itemService.uri}/project`
    const method = (isEmpty(data['@id'])) ? 'POST' : 'PUT'

    const contentType = (isEmpty(data['@context'])) ? 'application/json' : 'application/ld+json'
    try {
      await ky(useCorrectProtocol(uri), {
        method,
        json: data,
        headers: { Accept: 'application/ld+json', 'Content-Type': contentType }
      }).json()
      return true
    } catch (e) {
      console.error(e)
      if (!isEmpty(e.error) && !isEmpty(e.error.message))
        return false
      else {
        return false
      }
    }
  },

  async getProjectInfos (projectUri) { // TODO too slow
    const dataGraph = `${itemService.uri.replace('https', 'http')}/data`
    // TODO Empfehung Nate: Mit Union arbeiten oder zwei Queries draus machen
    const query = `prefix eal:   <http://tech4comp/eal/>
      select (COUNT(distinct ?item) AS ?itemQuantity) (COUNT( distinct ?lo) AS ?loQuantity)
      WHERE {
        GRAPH <${dataGraph}> {
          ?alles eal:hasProject <${projectUri}> .
          optional {
            ?item eal:difficulty ?x .
            filter (sameTerm (?alles, ?item))
          }
          optional {
            ?lo eal:hasBloomMapping ?y ;
              a eal:LearningOutcome .
              filter (sameTerm (?alles, ?lo))
          }
        }
      }`

    const response = await ky.post(sparqlEndpoint.uri, {
      headers: { 'Content-Type': 'application/sparql-query' },
      body: query
    }).json()

    const toReturn = {}
    response.head.vars.forEach((variable) => { toReturn[variable] = Number(response.results.bindings[0][variable].value) })
    return toReturn
  }
}
