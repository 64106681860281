import ky from 'ky'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import { useCorrectProtocol } from '@/common'
import { saveLog } from '@/api/logging'

const { itemService, sparqlEndpoint } = require('../config')

export default {
  async read (id) {
    try {
      const url = (id.startsWith('http')) ? id : `${itemService.uri}/item/${id}`
      const item = await ky.get(useCorrectProtocol(url)).json()
      item.expectedSolvability = Number.parseFloat(item.expectedSolvability)
      if (item.annotations && item.annotations.flagged)
        item.annotations.flagged = (item.annotations.flagged === 'true')
      return item
    } catch (e) {
      console.log(e)
      return undefined
    }
  },

  async readAll (project = '', detailed = false, mime = 'application/json', getContext = false) {
    try {
      let result = await ky.get(`${itemService.uri}/item/*${(!isEmpty(project)) ? `?project=${project}` : ''}${(detailed) ? '&detailed=true' : ''}`, {
        headers: { Accept: mime },
        timeout: 30000
      })
      if (mime === 'application/json') {
        result = await result.json()
        if (isEmpty(result['@graph']) && !isEmpty(result['@context'])) {
          const tmp = JSON.parse(JSON.stringify(result))
          delete tmp['@context']
          result = { '@context': result['@context'], '@graph': [tmp] }
        }
        result['@graph'] = result['@graph'].map((item) => {
          item.expectedSolvability = Number.parseFloat(item.expectedSolvability)
          if (item.annotations && item.annotations.flagged)
            item.annotations.flagged = (item.annotations.flagged === 'true')
          return item
        })
        if (getContext)
          return result
        else
          return result['@graph']
      } else if (mime === 'text/turtle')
        return result.text()
    } catch (e) {
      if (!isEmpty(e.response) && e.response.status === 404)
        return []
      console.log(e)
      return undefined
    }
  },

  async readAllForLinking (project = '') {
    try {
      const dataGraph = `${itemService.uri.replace('https', 'http')}/data`
      const query = `prefix eal:   <http://tech4comp/eal/>
        CONSTRUCT { ?s a ?o . ?s eal:hasLearningOutcome ?o2 }
        WHERE {
          GRAPH <${dataGraph}> {
            ?s eal:task ?x ;
               eal:hasProject <${project}> ;
               a ?o ;
               optional {
                 ?s eal:hasLearningOutcome ?o2 .
               }
          }
        }`

      const response = await ky.post(sparqlEndpoint.uri, {
        headers: { 'Content-Type': 'application/sparql-query', Accept: 'application/ld+json' },
        body: query
      }).json()
      if (!isEmpty(response['@graph']))
        return response
      else {
        const tmp = JSON.parse(JSON.stringify(response))
        delete tmp['@context']
        return { '@context': response['@context'], '@graph': [tmp] }
      }
    } catch (e) {
      if (!isEmpty(e.response) && e.response.status !== 404)
        console.log(e)
      return {}
    }
  },

  async delete (uri) {
    const oldItem = await this.read(uri)

    try {
      await ky.delete(useCorrectProtocol(uri))
      await saveLog({
        content: { new: null, old: oldItem },
        operation: 'deleted',
        contentType: 'item'
      })
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  },

  async write (data, id = undefined) {
    if (data.difficulty)
      data.difficulty = Number(data.difficulty)
    data.expectedSolvability = Number(data.expectedSolvability)
    let uri
    let method = 'PUT'
    switch (data['@type']) { // TODO find better way than to hardcode
      case 'http://tech4comp/eal/SingleChoiceItem':
        uri = `${itemService.uri}/item/singleChoice`
        break
      case 'http://tech4comp/eal/MultipleChoiceItem':
        uri = `${itemService.uri}/item/multipleChoice`
        break
      case 'http://tech4comp/eal/FreeTextItem':
        uri = `${itemService.uri}/item/freeText`
        break
      case 'http://tech4comp/eal/ArrangementItem':
        uri = `${itemService.uri}/item/arrangement`
        break
      case 'http://tech4comp/eal/RemoteItem':
        uri = `${itemService.uri}/item/remote`
        break
      default:
        uri = `${itemService.uri}/item`
    }

    // if id field is empty perform a post request and create the item
    // otherwise update the existing item
    if (isEmpty(id) && isEmpty(data['@id']))
      method = 'POST'
    else {
      id = (isEmpty(id)) ? last(data['@id'].split('/')) : id
      uri += '/' + id
    }

    const contentType = (isEmpty(data['@context'])) ? 'application/json' : 'application/ld+json'

    try {
      const oldItem = (method === 'POST') ? null : await this.read(uri)

      const newItem = await ky(useCorrectProtocol(uri), {
        method,
        json: data,
        headers: { 'Content-Type': contentType }
      }).json()

      await saveLog({
        content: { new: newItem, old: oldItem },
        operation: method === 'POST' ? 'created' : 'updated',
        contentType: 'item'
      })

      return newItem
    } catch (e) {
      console.error(e)
      if (!isEmpty(e.error) && !isEmpty(e.error.message))
        return { status: false, message: e.error.message }
      else {
        return { status: false }
      }
    }
  },

  async getItemsInfos (project) {
    const dataGraph = `${itemService.uri.replace('https', 'http')}/data`
    const query = `prefix eal:   <http://tech4comp/eal/>
      select (?o AS ?itemType) (COUNT(?s) AS ?quantity) (Count(?flaggedItems) AS ?flagged) (Count(?publishedItems) AS ?published)
      WHERE {
        GRAPH <${dataGraph}> {
          ?s eal:difficulty ?x ;
            eal:hasProject <${project}> ;
            a ?o .
          optional {
            ?s eal:annotation ?flaggedItems .
            ?flaggedItems eal:flagged true .
          }
          optional {
            ?s eal:annotation ?publishedItems .
            ?publishedItems eal:status "published" .
          }
        }
      } group by ?o`

    const response = await ky.post(sparqlEndpoint.uri, {
      headers: { 'Content-Type': 'application/sparql-query' },
      body: query
    }).json()

    return response.results.bindings.map((binding) => {
      const result = {}
      response.head.vars.forEach((variable) => {
        if (variable === 'itemType')
          result[variable] = binding[variable].value
        else
          result[variable] = Number(binding[variable].value)
      })
      return result
    })
  },

  async getReviewItemsInfos (project) {
    const dataGraph = `${itemService.uri.replace('https', 'http')}/data`
    const query = `prefix eal:   <http://tech4comp/eal/>
      select (COUNT(?new) AS ?newItems) (Count(?toRevise) AS ?itemsToRevise) (Count(?revisioned) AS ?revisionedItems) (Count(?deferred) AS ?deferredItems) (Count(?newF) AS ?newItemsFlagged) (Count(?toReviseF) as ?itemsToReviseFlagged) (Count(?revisionedF) as ?revisionedItemsFlagged) (Count(?deferredF) as ?deferredItemsFlagged) (Count(?published) as ?publishedItems)
      WHERE {
        GRAPH <${dataGraph}> {
          ?s eal:difficulty ?x ;
            eal:hasProject <${project}> .
          optional {
            ?s eal:annotation ?new .
            ?new eal:status "draft" .
          }
          optional {
            ?s eal:annotation ?newF .
            ?newF eal:status "draft" .
            ?newF eal:flagged true .
          }
          optional {
            ?s eal:annotation ?revisioned .
            ?revisioned eal:status "revisioned" .
          }
          optional {
            ?s eal:annotation ?revisionedF .
            ?revisionedF eal:status "revisioned" .
            ?revisionedF eal:flagged true .
          }
          optional {
            ?s eal:annotation ?toRevise .
            ?toRevise eal:status "toRevise" .
          }
          optional {
            ?s eal:annotation ?toReviseF .
            ?toReviseF eal:status "toRevise" .
            ?toReviseF eal:flagged true .
          }
          optional {
            ?s eal:annotation ?deferred .
            ?deferred eal:status "deferred" .
          }
          optional {
            ?s eal:annotation ?deferredF .
            ?deferredF eal:status "deferred" .
            ?deferredF eal:flagged true .
          }
          optional {
            ?s eal:annotation ?published .
            ?published eal:status "published" .
          }
        }
      }`

    const response = await ky.post(sparqlEndpoint.uri, {
      headers: { 'Content-Type': 'application/sparql-query' },
      body: query
    }).json()

    return response.results.bindings.map((binding) => {
      const result = {}
      response.head.vars.forEach((variable) => {
        result[variable] = {}
        result[variable].count = binding[variable].value
      })
      return result
    })[0]
  }
}
