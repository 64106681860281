import Vue from 'vue'
import Vuex from 'vuex'
import last from 'lodash/last'
import { itemService } from '@/config.js'
import concepts from '@/api/concepts.js'
import topicAPI from '@/api/topic.js'
import loAPI from '@/api/learningOutcome.js'

// Modules
import project from './modules/project'
import item from './modules/item'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    project,
    item
  },
  state: {
    optionsPerformanceLevels: [],
    optionsKnowledgeDimensions: [],
    optionsLearningOutcomes: [],
    optionsItemTypes: [],
    topics: [],
    learningOutcomes: [],
    showTutorial: true,
    tutorialText: '',
    user: null
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setLearningOutcomes (state, { toSet = [] }) {
      state.optionsLearningOutcomes = toSet
    },
    setKnowledgeDimensions (state, { toSet = [] }) {
      toSet.sort((a, b) => a.text > b.text ? 1 : -1)
      swapArrayElements(toSet, toSet.length - 1, toSet.length - 2) // TODO implement more robust way to do this
      state.optionsKnowledgeDimensions = toSet
    },
    setPerformanceLevels (state, { toSet = [] }) {
      swapArrayElements(toSet, 0, 4) // TODO implement more robust way to do this
      swapArrayElements(toSet, 5, 1) // TODO implement more robust way to do this
      swapArrayElements(toSet, 5, 2) // TODO implement more robust way to do this
      swapArrayElements(toSet, 3, 4) // TODO implement more robust way to do this
      state.optionsPerformanceLevels = toSet
    },
    setItemTypes (state, { toSet = [] }) {
      state.optionsItemTypes = toSet
    },
    setTutorialState (state, val) {
      state.showTutorial = val
      localStorage.setItem('showTutorial', state.showTutorial)
    },
    toogleTutorialState (state) {
      state.showTutorial = !state.showTutorial
      localStorage.setItem('showTutorial', state.showTutorial)
    },
    setTutorialText (state, text) {
      state.tutorialText = text
    },
    addTopic (state, topic) {
      state.topics.push(topic)
    },
    fillLearningOutcomes (state, LOs) {
      state.learningOutcomes = LOs
    }
  },
  actions: {
    async fillItemTypes (context) {
      return fillItemTypes.bind(context)()
    },
    async fillKnowledgeDimensions () {
      return fill.bind(this)('http://tech4comp/eal/KnowledgeDimension')
    },
    async fillPerformanceLevels () {
      return fill.bind(this)('http://tech4comp/eal/PerformanceLevel')
    },
    async fillLearningOutcomes (context) {
      const toSet = []
      let data = await loAPI.readAll(this.state.project.chosenProject)
      data = data.map((lo) => loAPI.read(last(lo['@id'].split('/'))))
      data = await Promise.all(data)
      context.commit('fillLearningOutcomes', data)
      data.forEach((lo) => {
        toSet.push({ text: lo.title, value: lo['@id'] })
      })
      this.commit('setLearningOutcomes', { toSet })
    },
    async addTopic (context, topic) {
      topic = await topicAPI.write({ title: topic })
      context.commit('addTopic', topic)
      return topic
    },
    async fillTopics (context) {
      const allTopics = await topicAPI.readAll()
      allTopics.forEach((topic) => context.commit('addTopic', topic))
    }
  }
})

const subClass = 'http://www.w3.org/2000/01/rdf-schema#subClassOf'
const type = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type'
const dataGraph = `${itemService.uri.replace('https', 'http')}/data`
const conceptsGraph = `${itemService.uri.replace('https', 'http')}/concepts`

async function fill (object, toSet = []) {
  const data = await concepts.get(type, object, dataGraph)
  data['@graph'].forEach((item) => {
    const germanTitle = item.title.filter(title => title['@language'] === 'de')[0]
    toSet.push({ text: germanTitle['@value'], value: item['@id'] })
  })
  if (object === 'http://tech4comp/eal/KnowledgeDimension')
    this.commit('setKnowledgeDimensions', { toSet })
  if (object === 'http://tech4comp/eal/PerformanceLevel')
    this.commit('setPerformanceLevels', { toSet })
}

async function fillItemTypes (toSet = []) {
  const itemTypes = await concepts.get(subClass, 'http://tech4comp/eal/Item', conceptsGraph)
  itemTypes['@graph'].reverse().forEach((itemType) => {
    toSet.push({ text: itemType.label['@value'].replace(' Item', ''), value: itemType['@id'] })
  })
  this.commit('setItemTypes', { toSet })
}

function swapArrayElements (array, index1, index2) {
  const tmp = array[index1]
  array[index1] = array[index2]
  array[index2] = tmp
}
