<template>
  <transition name="fade" mode="out-in" appear>
    <div v-if="$route.name === 'landing'" key="tutorial0">
      <p>Loggen Sie sich bei EAs.LiT ein um auf die Projektübersicht zu gelangen und um das Tool zu verwenden.</p>
      <auth-menu />
    </div>
    <div v-if="$route.name === 'projects'" key="tutorial1">
      <p>Erstellen Sie zuerst ein Projekt, in dem Ihre E-Assessment Aufgaben (Items), Learning Outcomes und sonstigen Daten gruppiert werden. Das erlaubt es ihnen verschiedene Projekte anzulegen, bspw. für mehrere Module bzw. Kurse.</p>
      <p>Klicken Sie auf ein Projekt, um dieses auszuwählen und darin zu arbeiten.</p>
    </div>
    <div v-if="$route.name === 'about'" key="tutorial2">
      <p>Hier sehen Sie eine Liste von genutzten Diensten, benutzten Drittpaketen und Einstlellungen für Ihre Session.</p>
    </div>
    <div v-if="$route.name === 'newItem' || $route.name === 'displayItem'" key="tutorial3">
      <p>Erstellen bzw. bearbeiten Sie hier Items. Dazu füllen Sie das Formular aus und klicken folgend auf "Validieren", wodurch das Formular geprüft wird und ggf. Fehler aufgezeigt und Hinweise gegeben werden. Sind keine Fehler enthalten können Sie per "Speichern" das Formular abschicken.</p>
      <p>Remote Items sind externe Items, für die nur Metadaten in EAs.LiT verwaltet werden. Somit können Items in entsprechend mächtigen System, wie bspw. Onyx, verwaltet werden, deren Zuordnung zu Learning Outcomes und zum Domänenmodell übernimmt aber EAs.LiT.</p>
      <p>Eine Vignette beschreibt den Kontext des Items, der zum verstehen der Aufgabenstellung nötig ist.</p>
    </div>
    <div v-if="$route.name === 'project'" key="tutorial4">
      <p>Erstellen Sie Learning Outcomes und folgend E-Assessment Aufgaben (Items). Learning Outcomes werden in Items benutzt und können auch während der Item-Erstellung erzeugt werden. Items selbst können aktuell erstellt, angezeigt, analysiert, importiert und exportiert werden.</p>
      <p>Sobald Items existieren können Sie diese in einem Item-Korb (vglb. Warenkorb) zusammenstellen und aus diesem Prüfungen erzeugen lassen.</p>
    </div>
    <div v-if="$route.name === 'itemList'" key="tutorial5">
      <p>In dieser Darstellung können Sie Items suchen, filtern und eingrenzen. Einzelne Items können über die Aktionsspalte bearbeitet, angesehen, gelöscht oder zum Item-Korb hinzugefügt bzw. entfernt werden. Klicken Sie ein Item an, so wird es in die Liste der Items aufgenommen, die Sie herunterladen möchten.</p>
    </div>
    <div v-if="$route.name === 'uploadItems'" key="tutorial6">
      <p>Hier können Sie Items in verschiedenen Formaten hochladen und so importieren. Wählen Sie dazu eine Datei und spezifizieren Sie ggf. einen Typ aus der vorgegeben Liste.</p>
      <p>Sofern eine Vorlage für eines der Datenformate benötigt wird, können vorhandene Items in jedem der Formate in der <router-link :to="`/projects/${this.$route.params.projectID}/items`">Export-Ansicht</router-link> exportiert werden.</p>
    </div>
    <div v-if="$route.name === 'learningOutcomes'" key="tutorial7">
      <p>Erstellen und bearbeiten Sie hier Learning Outcomes, um diese später bei der Item-Erstellung zuzuordnen. Das ermöglicht kontextuelle Assessment-Generierung, Filterung von Items und schafft für Sie einen Überblick, welche Learning Outcomes bereits mit Items befüllt worden sind.</p>
      <p>Learning Outcomes sind „Aussagen  darüber,  was  ein  Lernender  nach  dem  erfolgreichen  Abschluss  eines  Lernprozesses  weiß, versteht und in der Lage ist zu tun“ - <a href="https://ec.europa.eu/education/ects/users-guide/docs/ects-users-guide_de.pdf#page=22" target="_blank">ECTS Users‘ Guide 2015, Seite 22</a></p>
    </div>
    <div v-if="$route.name === 'itemBasket'" key="tutorial8">
        <p>Im Item-Korb können Sie den Korb überprüfen, Elemente aus dem Korb entfernen und ggf. aus dem Korb eine Prüfung generieren.</p>
        <p>Sie können Items selektieren - bspw. um die ausgewähten Items herunter zu laden - indem Sie diese anklicken.</p>
    </div>
    <div v-if="$route.name === 'exam'" key="tutorial9">
        <p>Hier können Sie Prüfungen aus dem Item-Korb generieren. Wählen Sie dazu Einschränkungen aus, nach denen Prüfungen und Alternativprüfungen generiert werden sollen.</p>
        <p>Für viele Einschränkungen ist es möglich eine minimale und maximale Grenze anzugeben. In diesem Fall entscheidet das System selbst, wie viele Items der entsprechenden Art in der Prüfung enthalten sein werden, unter- bzw. überschreitet dabei aber niemals die untere bzw. obere Grenze.</p>
    </div>
    <div v-if="$route.name === 'ontodia'" key="tutorial10">
        <p>Diese Ansicht bietet ihnen eine Analysemöglichkeit Ihrer Items bzgl. verknüpfter Learning Outcomes und einer der vorhandenen annotierten Fachlandkarten. Wählen Sie dazu die Fachlandkarte aus, mit der Sie die Items bzw. Learning Outcomes verknüpfen wollen.</p>
    </div>
    <div v-if="$route.name === 'review'" key="tutorial11">
      <p>Sie können aus einer Fülle von Optionen wählen, um Items zu begutachten. Die Optionen orientieren sich an den möglichen Stati der Items:
        <ul style="padding-left: 1.5rem;">
          <li>Neu/Entwurf</li>
          <li>Zu bearbeiten</li>
          <li>Bearbeitet</li>
          <li>Veröffentlicht</li>
          <li>Zurückgestellt</li>
        </ul>
      </p>
    </div>
    <div v-if="$route.name === 'reviewDetail'" key="tutorial12">
        <p>Sie sehen eine Liste der Items des gewählten Status. Sie können diese über die Filter, oben in der Ansicht, einschränken. Bei einem Klick auf ein Item öffnet sich ein Dialog, der Sie durch die möglichen Statusänderungen führt.</p>
    </div>
    <div v-if="$route.name === 'linking'" key="tutorial13">
        <p>Wählen Sie eine Fachlandkarte aus, mit der Sie Ihre Items verlinken möchten. In dem geöffneten Graphen können Sie einzelne Items doppelt anklicken. Dadurch öffnet sich ein Dialog, der es Ihnen erlaubt das Item mit der Fachlandkarte zu verknüpfen.</p>
        <p>Sie können Knoten der Karte auswählen und mit der Taste "entf" aus der Übersicht entfernen (der Knoten wird nicht gelöscht, nur nicht mehr dargestellt). Sie können Zoomen indem Sie "strg" gedrückt halten und das Mausrad bewegen. Für eine übersichtlichere Darstellung können Sie Knoten entfernen und folgend den "Layout"-Knopf betätigen.</p>
    </div>
    <div v-if="$route.name === 'help' || $route.name === 'specialHelp'" key="tutorial14">
      <p>Navigieren Sie zu den verfügbaren Hilfeseiten um sich Hilfe zu verschiedenen Themen zu holen. Von einer bestimmten Hilfeseite können Sie jederzeit zurück zur Übersicht gelangen, indem Sie in der Statusleiste auf "Hilfe" klicken.</p>
    </div>
    <div v-if="$route.name === 'logs'">
      <p>Diese Ansicht bietet Ihnen einen Ereignisverlauf des aktuellen Projektes. Ereignisse können nach verschiedenen Aspekten gefiltert werden, wie bspw. nach Zeitraum, Nutzer:innen oder nach Log-Ebene. Sie können mit dieser Ansicht bspw. die Aktionen einzelner Nutzer:innen nachvollziehen.</p>
    </div>
  </transition>
</template>

<script>
import authMenu from './authMenu.vue'

export default {
  name: 'tutorial',
  components: { authMenu }
}
</script>

<style scoped>
  div > p {
    hyphens: auto;
  }
</style>
