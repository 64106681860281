import ky from 'ky'
import isEmpty from 'lodash/isEmpty'
import { useCorrectProtocol } from '@/common'
const { itemService } = require('../config')
const { sparqlEndpoint } = require('../config')

export default {
  async read (id) {
    const url = (id.startsWith('http')) ? id : `${itemService.uri}/learningoutcome/${id}`
    try {
      return await ky.get(useCorrectProtocol(url)).json()
    } catch (e) {
      console.log(e)
      return undefined
    }
  },

  async readAll (project = '', mime = 'application/json') {
    try {
      let result = await ky.get(`${itemService.uri}/learningoutcome/*${(!isEmpty(project)) ? `?project=${project}` : ''}`, {
        headers: { Accept: mime }
      }).text()
      if (mime === 'application/json' || mime === 'application/ld+json') {
        result = JSON.parse(result)
        return result['@graph'] ? result['@graph'] : [result]
      } else if (mime === 'text/turtle')
        return result
    } catch (e) {
      if (!isEmpty(e.response) && e.response.status !== 404)
        console.log(e)
      if (mime === 'text/turtle')
        return ''
      return []
    }
  },

  async delete (uri) {
    try {
      await ky.delete(useCorrectProtocol(uri))
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  },

  async write (data) {
    const uri = (isEmpty(data['@id'])) ? `${itemService.uri}/learningoutcome` : data['@id']
    const method = (isEmpty(data['@id'])) ? 'POST' : 'PUT'

    const contentType = (isEmpty(data['@context'])) ? 'application/json' : 'application/ld+json'
    try {
      await ky(useCorrectProtocol(uri), {
        method,
        json: data,
        headers: { Accept: 'application/ld+json', 'Content-Type': contentType }
      }).json()
      return true
    } catch (e) {
      console.error(e)
      if (!isEmpty(e.error) && !isEmpty(e.error.message))
        return false
      else {
        return false
      }
    }
  },

  async getLOInfos (project) {
    const dataGraph = `${itemService.uri.replace('https', 'http')}/data`
    const query = `prefix eal:   <http://tech4comp/eal/>
        select (COUNT(distinct ?s) AS ?count) WHERE {
        GRAPH <${dataGraph}> {
          ?s a <http://tech4comp/eal/LearningOutcome> .
          ?s eal:hasProject <${project}> .
        }
      }`

    const response = await ky.post(sparqlEndpoint.uri, {
      headers: { 'Content-Type': 'application/sparql-query' },
      body: query
    }).json()

    return { count: response.results.bindings[0].count.value }
  },

  async getLOInfo (loUri, projectUri) {
    const dataGraph = `${itemService.uri.replace('https', 'http')}/data`
    const query = `prefix eal:   <http://tech4comp/eal/>
      select (COUNT(distinct ?item) AS ?itemQuantity)
      WHERE {
        GRAPH <${dataGraph}> {
          optional {
            ?item eal:task ?x .
            ?item eal:hasProject <${projectUri}> .
            ?item eal:hasLearningOutcome <${loUri}>
          }
        }
      }`

    const response = await ky.post(sparqlEndpoint.uri, {
      headers: { 'Content-Type': 'application/sparql-query' },
      body: query
    }).json()

    const toReturn = {}
    response.head.vars.forEach((variable) => { toReturn[variable] = Number(response.results.bindings[0][variable].value) })
    return toReturn
  }
}
