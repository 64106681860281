import { createOidcAuth, SignInType } from 'vue-oidc-client/vue2'
import { WebStorageStateStore } from 'oidc-client'
import store from './store/index'
import { oAuthProvider } from '@/config'

const appUrl = window.location.origin + '/'

const mainOidc = createOidcAuth('main', SignInType.Popup, appUrl, {
  authority: oAuthProvider.authority,
  client_id: oAuthProvider.client_id,
  response_type: oAuthProvider.response_type,
  scope: oAuthProvider.scope,
  post_logout_redirect_uri: appUrl,
  userStore: new WebStorageStateStore({ store: window.localStorage })
})

mainOidc.events.addUserLoaded(() => {
  if (mainOidc.userProfile.roles === undefined || !mainOidc.userProfile.roles.includes('t4c-lehrende')) {
    window.alert('Ihr Account ist für diese Anwendung nicht freigeschaltet')
    mainOidc.signOut()
    return
  }
  store.commit('setUser', mainOidc.userProfile)
  if (mainOidc.myRouter.history.current.name === 'landing') // redirect if on landing page
    mainOidc.myRouter.push({ path: '/projects' })
})

mainOidc.events.addUserSignedOut(() => {
  store.commit('setUser', null)
})

mainOidc.events.addUserUnloaded(() => {
  store.commit('setUser', null)
})

mainOidc.events.addAccessTokenExpiring(() => {
  mainOidc.startSilentRenew()
})

mainOidc.events.addSilentRenewError(() => {
  mainOidc.signOut()
})

mainOidc.events.addAccessTokenExpired(() => {
  mainOidc.signOut()
})

export default mainOidc
