import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '@/views/Landing.vue'
import store from '@/store/index'
import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'
import Authentication from '@/auth'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing,
      beforeEnter: clearStore
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import(/* webpackChunkName: "project" */ '@/views/ProjectOverview.vue'),
      beforeEnter: clearStore,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID',
      name: 'project',
      component: () => import(/* webpackChunkName: "project" */ '@/views/Project.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/newItem',
      name: 'newItem',
      component: () => import(/* webpackChunkName: "item" */ '@/views/Item.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/item/:id',
      name: 'displayItem',
      component: () => import(/* webpackChunkName: "item" */ '@/views/Item.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/items',
      name: 'itemList',
      component: () => import(/* webpackChunkName: "item" */ '@/views/ItemList.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/uploadItems',
      name: 'uploadItems',
      component: () => import(/* webpackChunkName: "item" */ '@/views/UploadItems.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/learningoutcomes',
      name: 'learningOutcomes',
      component: () => import(/* webpackChunkName: "learningoutcome" */ '@/views/smallDataList.vue'),
      props: { title: 'Learning Outcomes', variant: 'learningOutcomes' },
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/itembasket',
      name: 'itemBasket',
      component: () => import(/* webpackChunkName: "itemBasket" */ '@/views/ItemBasket.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/review',
      name: 'review',
      component: () => import(/* webpackChunkName: "item" */ '@/views/Review.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/review/show',
      name: 'reviewDetail',
      component: () => import(/* webpackChunkName: "item" */ '@/views/ReviewDetail.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/exam',
      name: 'exam',
      component: () => import(/* webpackChunkName: "exam" */ '@/views/Exam.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/ontodia',
      name: 'ontodia',
      component: () => import(/* webpackChunkName: "analytics" */ '@/views/Ontodia.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/linking',
      name: 'linking',
      component: () => import(/* webpackChunkName: "analytics" */ '@/views/Ontodia.vue'),
      props: { dynamicLinking: true },
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },
    {
      path: '/projects/:projectID/logs',
      name: 'logs',
      component: () => import(/* webpackChunkName: "logs" */ '@/views/Logs.vue'),
      beforeEnter: redirectIfProjectNotValid,
      meta: {
        authName: Authentication.authName
      }
    },

    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
      beforeEnter: clearStore
    },
    {
      path: '/help',
      name: 'help',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Help.vue'),
      beforeEnter: clearStore
    },
    {
      path: '/help/:site',
      name: 'specialHelp',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Help.vue'),
      beforeEnter: clearStore
    }
  ]
})

async function redirectIfProjectNotValid (to, from, next) {
  const id = Number(to.params.projectID)

  if (Number.isInteger(id) && id >= 0 && id <= Number.MAX_SAFE_INTEGER) {
    const projects = await getProjects()
    const matchingProject = projects.find((project) => Number(last(project['@id'].split('/'))) === id)
    if (isEmpty(matchingProject)) {
      store.commit('project/setChosenProject')
      next('/error')
    } else {
      store.commit('project/setChosenProject', matchingProject['@id'])
      next()
    }
  } else {
    store.commit('project/setChosenProject')
    next('/error')
  }
}

function getProjects () {
  return new Promise((resolve, reject) => {
    if (isEmpty(store.state.project.projects)) {
      const unwatch = store.watch(() => store.state.project.projects, (value) => {
        unwatch()
        resolve(value)
      })
    } else {
      resolve(store.state.project.projects)
    }
  })
}

function clearStore (to, from, next) {
  store.commit('item/fillItems', [])
  store.commit('item/setItemInfos', [])
  store.commit('project/setChosenProject', null)
  if (store.getters['project/getCurrentProject'])
    store.commit('item/clearItemBasket', store.getters['project/getCurrentProject']['@id'])
  next()
}
