import itemAPI from '@/api/item.js'
import isEmpty from 'lodash/isEmpty'

const itemModule = {
  namespaced: true,
  state: {
    items: {},
    itemBasket: [],
    itemInfos: []
  },
  mutations: {
    setItemInfos (state, itemInfos) {
      state.itemInfos = itemInfos
    },
    fillItems (state, items) {
      state.items = items
    },
    addToItemBasket (state, { item, projectID }) {
      // NOTE alter fillItemBasket if this is altered
      const index = state.itemBasket.findIndex(
        basketItem => item['@id'] === basketItem['@id']
      )
      if (index === -1) {
        state.itemBasket.push(item)
        localStorage.setItem(
          'itemBasket' + projectID,
          JSON.stringify(state.itemBasket)
        )
      }
    },
    removeFromItemBasket (state, { item, projectID }) {
      const index = state.itemBasket.findIndex(
        basketItem => item['@id'] === basketItem['@id']
      )
      if (index !== -1) {
        state.itemBasket.splice(index, 1)
        localStorage.setItem(
          'itemBasket' + projectID,
          JSON.stringify(state.itemBasket)
        )
      }
    },
    clearItemBasket (state, projectID) {
      state.itemBasket = []
      localStorage.removeItem('itemBasket' + projectID)
    },
    addListToItemBasket (state, { items, projectID }) {
      items.forEach(item => {
        // TODO same as in addToItemBasket
        const index = state.itemBasket.findIndex(
          basketItem => item['@id'] === basketItem['@id']
        )
        if (index === -1) state.itemBasket.push(item)
      })
      localStorage.setItem(
        'itemBasket' + projectID,
        JSON.stringify(state.itemBasket)
      )
    },
    replaceItemBasket (state, items) {
      state.itemBasket = items
    },
    loadItemBasketFromLocalstorage (state, projectID) {
      let itemBasket = localStorage.getItem('itemBasket' + projectID)
      if (!isEmpty(itemBasket)) {
        itemBasket = JSON.parse(itemBasket)
        state.itemBasket = itemBasket
      }
    }
  },
  actions: {
    async loadItemInfos (context, projectId) {
      const itemInfos = await itemAPI.getItemsInfos(projectId)

      return context.commit('setItemInfos', itemInfos)
    }
  }

}

export default itemModule
