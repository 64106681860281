<template>
  <b-sidebar id="sidebar-1" backdrop @shown="sidebarOpened">
    <div>

      <!-- Projektübersicht -->
      <router-link class="nav-item-sidebar seperation-divider-bottom projectOverview" :to="$route.params.projectID ? '/projects/' + $route.params.projectID : '/projects'" active-class="active-item" exact>
        <div class="nav-icon">
          <font-awesome-icon icon="home" />
        </div>
        <span class="link--label">
          <span v-if="$route.params.projectID">Projektübersicht</span>
          <span v-else>Alle Projekte</span>
        </span>
      </router-link>

    <!-- Navigationsziele -->
    <div v-for="destination in destinations" :key="destination.title" class="seperation-divider-bottom">
      <div class="destination-group-title">{{ destination.title }}</div>
        <div v-for="link in destination.links" :key="link.label" class="nav-item-sidebar" @click="link.disabled ? '' : openLink(link.to, link.external || false)" :class="{ 'active-item-sidebar': $route.path === link.to, 'disabled-item-sidebar': (link.disabled || (!$store.state.project.chosenProject && !link.external)) }">
          <div class="nav-icon">
            <font-awesome-icon :icon="link.icon" />
          </div>
          <span class="link--label">
            {{ link.label }}
            <font-awesome-icon v-if="link.external" size="xs" icon="external-link-alt" style="margin-left: 0.5rem;"/>
          </span>
        </div>
      </div>
    </div>

  <template #footer>
    <div class="d-sm-none mb-3">
      <auth-menu mode="list-item" />
    </div>
  </template>

  </b-sidebar>
</template>

<script>
import authMenu from './authMenu'
import { dskgWebApp } from '@/config.js'

export default {
  components: {
    authMenu
  },
  computed:
    {
      itemsInfos () {
        return this.$store.state.item.itemInfos
      },
      amountOfItems () {
        return this.itemsInfos.map((a) => a.quantity).reduce((a, b) => a + b, 0)
      },
      amountOfPublishedItems () {
        return this.itemsInfos.map((a) => a.published).reduce((a, b) => a + b, 0)
      },
      amountOfUnpublishedItems () {
        return this.amountOfItems - this.amountOfPublishedItems
      },
      destinations () {
        const projectID = this.$route.params.projectID || 'projectID'

        return [
          {
            title: 'Ressourcen',
            links: [
              { label: 'Learning Outcomes', icon: 'graduation-cap', to: `/projects/${projectID}/learningoutcomes` },
              { label: 'Items', icon: 'file-alt', to: `/projects/${projectID}/items`, disabled: this.amountOfItems === 0, disabled_tooltip: '' },
              { label: 'Fachlandkarten', icon: 'map-marked-alt', to: dskgWebApp.uri, external: true }
            ]
          },
          {
            title: 'Aktionen',
            links: [
              { label: 'Item Import', icon: 'download', to: `/projects/${projectID}/uploadItems` },
              { label: 'Item Export', icon: 'file-export', to: `/projects/${projectID}/items`, disabled: this.amountOfItems === 0 },
              { label: 'Items begutachten', icon: 'edit', to: `/projects/${projectID}/review`, disabled: this.amountOfUnpublishedItems === 0 },
              { label: 'Items verlinken', icon: 'link', to: `/projects/${projectID}/linking`, disabled: this.amountOfItems === 0 },
              { label: 'Prüfungserzeugung', icon: 'graduation-cap', to: `/projects/${projectID}/exam`, disabled: this.$store.state.item.itemBasket.length === 0 }
            ]
          },
          {
            title: 'Analyse',
            links: [
              { label: 'Item-Korb Analyse', icon: 'tasks', to: `/projects/${projectID}/itembasket`, disabled: this.$store.state.item.itemBasket.length === 0 },
              { label: 'Ereignisverlauf', icon: 'history', to: `/projects/${projectID}/logs`, disabled: this.$store.state.user === null }
            ]
          }
        ]
      }
    },
  methods: {
    async sidebarOpened () {
      if (this.itemsInfos.length === 0) await this.$store.dispatch('item/loadItemInfos', this.$store.state.project.chosenProject)
    },
    openLink (link, external = false) {
      if (!external) {
        if (this.$route.params.projectID) {
          this.$router.push({ path: link })
        }
      } else {
        window.open(link, '_blank')
      }
    }
  }
}
</script>

<style>
  .seperation-divider-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .destination-group-title {
    padding-left: 20px;
    text-transform: uppercase;
    color: rgba(0,0,0,0.8);
    font-weight: 400;
    font-size: 0.8em;
    margin-top: 10px;
  }

  .link--label {
    color: #666666;
    font-size: 15px;
  }

  .nav-item-sidebar {
    font-size: 16px;
    display: flex;
    text-decoration: none !important;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 20px;
    transition: all 0.3s;
    cursor: pointer;
  }

  .nav-item-sidebar:hover {
    background-color: rgba(0,0,0,0.1);
    color: black;
  }

  .active-item-sidebar {
      background: rgba(0,0,0,0.1);
      font-weight: 600;
  }

  .active-item-sidebar .link--label {
    color: rgba(0,0,0,0.8);
  }

  .active-item-sidebar .nav-icon {
    color: rgba(0,0,0,0.8);
  }

  .disabled-item-sidebar {
    background: none;
    cursor: default;
  }

  .disabled-item-sidebar:hover {
    background: none;
  }

  .disabled-item-sidebar .link--label {
    color: rgba(0,0,0,0.4);
  }

  .disabled-item-sidebar .nav-icon {
    color: rgba(0,0,0,0.4);
  }

  .nav-icon {
    width: 35px;
    color: #666666;
  }

  #sidebar-1 {
    width: 260px;
    border-right: 1px solid rgba(0,0,0,0.4);
    z-index: 1020;
  }

  .section--header {
    letter-spacing: -0.5px;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 5px;
    margin-top: 2px;
    padding-left: 15px;
  }

  .projectOverview {
    height: 70px;
    display: flex;
    align-content: center;
    align-items: center;
  }
</style>
