<template>
  <div>
    <b-jumbotron>
      <template #header>
        <b-container fluid>
          <b-row no-gutters>
            <b-col id="heading">
              EAs.LiT v2
            </b-col>
            <b-col md="5" cols="8" sm="auto" class="headerIcons" align="right">
              <font-awesome-icon icon="chalkboard-teacher"/>
              <font-awesome-icon icon="clipboard-check"/>
              <font-awesome-icon icon="graduation-cap"/>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template #lead>
        Das E-Assessment-Literacy Tool
      </template>

      <hr class="my-4">

      <p>
        Das E-Assessment-Literacy-Tool (EAs.LiT) ist ein Web-basiertes System zur kollaborativen, qualitätsgesicherten Erstellung von Aufgaben (Items) für E-Assessments unter Berücksichtigung des Constructive Alignment. EAs.LiT unterstützt dazu hochschuldidaktisch fundiert bei der Formulierung von Learning Outcomes, der darauf basierenden Erstellung und Begutachtung von Aufgaben sowie der kriterienbasierten semi-automatischen Zusammenstellung gleichwertiger E-Prüfungen. Der Begutachtungsprozess ermöglicht die Erstellung strukturierter Reviews zur Sicherung der Item-Qualität hinsichtlich fachlicher Richtigkeit, Relevanz und Formulierung. Des Weiteren unterstützt EAs.LiT die Erstellung von Item-Pools für Prüfungen durch eine interaktive, mehrdimensionale Datenexploration innerhalb des Item-Bestands basierend auf Strukturplänen für Prüfungen (Blueprints).
      </p>

      <hr class="my-4">

      <b-container fluid>
        <b-row align-v="center" v-if="!isEmpty($auth)">
          <b-col>
            <p>Loggen Sie sich jetzt ein um EAs.LiT zu benutzten. Haben sie noch kein Nutzerkonto? Kein Problem! Legen Sie einfach ein neues Benutzerkonto bei Learning-Layers an und benutzen Sie dieses um sich bei EAs.LiT anzumelden.</p>
          </b-col>
          <b-col xl="3" lg="4" cols="3" class="authButtons" align="right">
            <b-button variant="primary" @click="$auth.signIn()">Login <font-awesome-icon icon="sign-in-alt"/></b-button>
            <b-button variant="success" href="https://api.learning-layers.eu/account/public/NewUser" target="_blank">Konto anlegen <font-awesome-icon icon="user-plus"/></b-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col align="center">
            <b-button variant="primary" to="/projects">Zu den Projekten <font-awesome-icon icon="folder-open"/></b-button>
          </b-col>
        </b-row>
      </b-container>

      <hr class="my-4">

      <b-row align="center" align-v="center">
        <b-col sm="12" md="12" xl="4">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters align-v="center">
              <b-col align="center" class="cardIcons" cols="3" sm="3" md="3" lg="4" xl="5">
                <font-awesome-icon icon="file-alt" size="10x"/>
              </b-col>
              <b-col>
                <b-card-body title="Item-Erstellung">
                  <b-card-text>
                    Erstellen Sie Items in einem geführtem Prozess und mit jeder Menge Metadaten, welche zur automatischen Prüfungs&shy;er&shy;zeu&shy;gung, Verknüpfung zu Fach&shy;land&shy;karten und für Analysen ge&shy;nutzt werden.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters align-v="center">
              <b-col align="center" class="cardIcons" cols="3" sm="3" md="3" lg="4" xl="5">
                <font-awesome-layers class="fa-9x">
                  <font-awesome-icon icon="file"/>
                  <font-awesome-icon icon="file" transform="grow-1"/>
                  <font-awesome-icon icon="search" transform="shrink-6 down-1" style="color: rgba(255, 255, 255, 0.875);"/>
                </font-awesome-layers>
              </b-col>
              <b-col>
                <b-card-body title="Item-Begutachtung">
                  <b-card-text>
                    Items durchlaufen einen umfangreichen Be&shy;gut&shy;ach&shy;tung&shy;spro&shy;zess, sodass deren Qualität zu jeder Zeit sichergestellt ist und keine Entwürfe in Prüfungen landen.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters align-v="center">
              <b-col align="center" class="cardIcons" cols="3" sm="3" md="3" lg="4" xl="5">
                <font-awesome-layers class="fa-8x">
                  <font-awesome-icon icon="file"/>
                    <font-awesome-icon icon="file" transform="grow-1"/>
                    <font-awesome-icon icon="list-ul" transform="shrink-8 down-2" style="color: rgba(255, 255, 255, 0.875);"/>
                    <font-awesome-icon icon="graduation-cap" transform="shrink-5 up-8 right-4 rotate-45"/>
                </font-awesome-layers>
              </b-col>
              <b-col>
                <b-card-body title="Prüfungserzeugung">
                  <b-card-text>
                    Prüfungen werden automatisiert erzeugt nachdem Kriterien für deren Zusammenstellung festgelegt wurden. Das erlaubt es mehrere Prüfungen zu erzeugen, welche vom Schwierig&shy;keits&shy;grad und bspw. Themen&shy;spek&shy;trum äquivalent sind.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col>
          <b-carousel :interval="5000" controls fade indicators img-width="1200" img-height="700">

            <b-carousel-slide caption="Projektübersicht" text="Zeigt die verschiedenen Optionen im Projekt und führt initial durch diese hindurch" img-src="/landing_carousel_img/project_overview.png" img-alt="Projektübersicht und Optionen"/>
            <b-carousel-slide caption="Item Erstellung" text="Items werden mit vielen Metadaten in einem geführtem Prozess erstellt, die später bei der Prüfungserzeugung und für externe Anwendungen genutzt werden" img-src="/landing_carousel_img/item_form.png" img-alt="Item-Eingabemaske"/>
            <b-carousel-slide caption="Verknüpfung zum Fach-Kontext" text="Items können zu Fachlandkarten verknüpft werden, was viele neue Analyse-, Prozess- und Weiterverwendungsmöglichkeiten eröffnet" img-src="/landing_carousel_img/linking.png" img-alt="Verknüpfungsansicht"/>
            <b-carousel-slide caption="Prüfungserzeugung" text="Prüfungen und äquivalente Alternativprüfungen können mit vielen Kriterein zielgenau erzeugt werden" img-src="/landing_carousel_img/exam.png" img-alt="Prüfungsansicht"/>
          </b-carousel>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'landing',
  title: 'EAs.LiT',
  methods: {
    isEmpty
  }
}
</script>

<style>
  div.carousel-caption {
    opacity: 0.75;
    border-radius: 1rem;
    background: black;
  }
  @media (max-width: 800px) {
    div.carousel-caption > h3 {
      font-size: 1.5rem;
    }
    div.carousel-caption > p {
      font-size: 0.9rem;
    }
  }
  .carousel-control-next, .carousel-control-prev {
    opacity: 0.75;
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    border-radius: 1rem;
    padding: 0.7rem;
    border: 0.5rem solid black;
    background-color: black !important;
  }
  .carousel-item > img {
    border-radius: 0.3rem;
  }
</style>

<style scoped>
  .jumbotron {
    padding-top: 1.5rem;
  }
  .container-fluid {
    padding: 0;
  }
  .card {
    margin: 1rem 0 1rem 0;
    max-width: 540px;
  }
  .card > .row > div:last-child {
    border-left: 1px solid black;
  }
  .headerIcons > svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  @media (max-width: 650px) {
    #heading {
      font-size: 4rem !important;
    }
    .cardIcons {
      font-size: 0.5rem;
    }
  }
  @media (max-width: 800px) {
    .headerIcons > svg {
      font-size: 3rem !important;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
    }
  }
  .authButtons > button {
    margin-right: 1rem;
  }
  @media (max-width: 991px) {
    .authButtons button {
      margin: 0 0 1rem 0;
    }
    .cardIcons {
      font-size: 0.7rem;
    }
  }
</style>
