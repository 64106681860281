import ky from 'ky'
import isEmpty from 'lodash/isEmpty'
const { sparqlEndpoint } = require('../config')
const jsonld = require('jsonld')

export default {
  async get (p, o, graph) {
    const query = `CONSTRUCT { ?s ?p ?o } WHERE {
       GRAPH <${graph}> {
        ?s <${p}> <${o}> .
        ?s ?p ?o .
       }
      }`

    const context = {
      comment: 'http://www.w3.org/2000/01/rdf-schema#comment',
      label: 'http://www.w3.org/2000/01/rdf-schema#label',
      title: 'http://purl.org/dc/terms/title',
      abbreviation: 'http://dbpedia.org/ontology/abbreviation',
      description: 'http://purl.org/dc/terms/description'
    }

    try {
      const response = await ky.post(sparqlEndpoint.uri, {
        headers: { 'Content-Type': 'application/sparql-query', Accept: 'application/n-triples' },
        body: query
      }).text()
      return jsonld.compact(await jsonld.fromRDF(response), context)
    } catch (e) {
      if (!isEmpty(e.response) && e.response.status !== 404)
        console.log(e)
      return {}
    }
  }
}
