<template>
  <b-sidebar id="sidebar-2" style="z-index: 1010;" v-model="showTutorial" right no-close-on-route-change>
    <div class="pt-4 top-header sidebar-content">
      <span>Tutorial</span>
      <b-button @click="showTutorial = false" variant="flat"><font-awesome-icon icon="times" /></b-button>
    </div>

    <div class="sidebar-content pt-4">
      <tutorial />
      <legende />
    </div>
  </b-sidebar>
</template>

<script>
import tutorial from './tutorial.vue'
import legende from './legende.vue'

export default {
  components: { tutorial, legende },
  computed: {
    showTutorial: {
      get () {
        return this.$store.state.showTutorial
      },
      set (val) {
        this.$store.commit('setTutorialState', val)
      }
    }
  }
}
</script>

<style scoped>
  #sidebar-2 {
    border-left: 1px solid rgba(0,0,0,0.1);
  }

  .sidebar-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .top-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-top: 5px;
    padding-bottom: 5px;
  }

  span {
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
</style>
