<template>
  <div style="overflow-x: hidden">
    <left-sidebar />
    <tutorial-sidebar />

    <b-container id="app" fluid>
      <navbar id="header" />

      <div id="main" class="mainpage-wrapper">
        <b-container class="mainpage-container" :fluid="$route.name === 'landing'">
          <transition name="fade" mode="out-in" appear>
            <router-view />
          </transition>
        </b-container>
      </div>
    </b-container>

  </div>
</template>

<script>
import navbar from '@/components/navbar.vue'
import leftSidebar from '@/components/leftSidebar.vue'
import tutorialSidebar from '@/components/tutorialSidebar.vue'

export default {
  name: 'app',
  title: 'EAs.LiT',
  components: {
    navbar,
    leftSidebar,
    tutorialSidebar
  }
}
</script>

<style>
  .mainpage-wrapper {
    padding-right: 0px;
    transition: all 0.25s;
    margin-top: 80px;
  }

  /* Targets Laptop Screen Size */
  @media (min-width: 600px) and (max-width: 1900px) {
    .tutorial-is-open {
      padding-right: 320px;
    }
  }

  html, body{
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  }
  .main-column {
    transition: all 0.25s;
  }
  #app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  #main {
    flex-grow: 1;
    padding-bottom: 0;
  }
  #main > div {
    margin-bottom: 1rem;
  }
  #header, #main, #footer {
    flex-shrink: 0;
  }
  #footer > .col {
    padding: 0 0 0 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .badge-SC {
    background-color: rgba(76,175,80,1) !important;
    color: black !important;
  }
  .badge-MC {
    background-color: rgba(0,188,212,1) !important;
    color: black !important;
  }
  .badge-FT {
    background-color: rgba(255,87,34,1) !important;
    color: black !important;
  }
  .badge-AR {
    background-color: rgba(63,81,181,1) !important;
  }
  .badge-AS {
    background-color: rgba(0,0,0 ,1) !important;
  }
  .badge-RE {
    background-color: rgba(121,85,72,1) !important;
  }
  .badge > .svg-inline--fa {
    margin-top: 0.1rem;
  }
  .card-text {
    hyphens: auto;
    text-align: justify;
  }
  .verticalLine {
    height: 2rem;
    border: 1px solid grey;
    margin-right: 0.5rem;
  }
</style>
