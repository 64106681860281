const itemService = {
  uri: 'https://item.easlit.erzw.uni-leipzig.de'
}
const sparqlEndpoint = {
  uri: 'https://quit.easlit.erzw.uni-leipzig.de/eal',
  knowledgeMaps: 'https://quit.easlit.erzw.uni-leipzig.de/knowledgeMaps'
}

const formatService = {
  uri: 'https://import.easlit.erzw.uni-leipzig.de'
}

const knowledgeMapService = {
  uri: 'https://wu.easlit.erzw.uni-leipzig.de'
}

const examService = {
  uri: 'https://ub.easlit.erzw.uni-leipzig.de'
}

const logService = {
  uri: 'https://legacy.easlit.erzw.uni-leipzig.de'
}

const dskgWebApp = {
  uri: 'https://export.easlit.erzw.uni-leipzig.de'
}

const demoMode = false

const oAuthProvider = {
  authority: 'https://auth.las2peer.org/auth/realms/main',
  client_id: 'a4b3f15a-eaec-489a-af08-1dc9cf57347e',
  response_type: 'id_token token',
  scope: 'openid profile'
}

export { itemService, sparqlEndpoint, formatService, knowledgeMapService, examService, oAuthProvider, logService, dskgWebApp, demoMode }
