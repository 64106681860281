import projectAPI from '@/api/project.js'
import last from 'lodash/last'

const projectModule = {
  namespaced: true,
  state: {
    optionsProjects: [],
    chosenProject: null,
    projects: []
  },
  mutations: {
    setProjects (state, { toSet = [] }) {
      state.optionsProjects = toSet
    },
    setFullProjects (state, { toSet = [] }) {
      state.projects = toSet
    },
    setChosenProject (state, project = null) {
      state.chosenProject = project
    }
  },
  getters: {
    getCurrentProject (state) {
      const currentProject = state.projects.find(
        project => project['@id'] === state.chosenProject
      )

      if (!currentProject) return null
      return currentProject
    },
    getCurrentProjectId (state) {
      if (!state.chosenProject) return undefined

      return last(state.chosenProject.split('/'))
    }
  },
  actions: {
    async fillProjects (context) {
      let projects = await projectAPI.readAll()
      projects = projects.map(project =>
        projectAPI.read(Number(last(project['@id'].split('/'))))
      )
      projects = await Promise.all(projects)
      projects = projects.sort(
        (a, b) =>
          Number(last(a['@id'].split('/'))) <= Number(last(b['@id'].split('/'))) ? 1 : -1
      )
      context.commit('setFullProjects', { toSet: projects })
      projects = projects.map(project => {
        return { text: project.title, value: project['@id'] }
      })
      context.commit('setProjects', { toSet: projects })
    }
  }
}

export default projectModule
