import ky from 'ky'
import store from '@/store/index'
import { logService } from '@/config'

/*
  userInfo: { username:string, userId: string}
  projectId: string
  contentType: string
  operation: string
  content: { new: Object | null, old: Object | null }
  id: logId (created on server)
*/

export async function saveLog (entries) {
  const endpointURL = `${logService.uri}/logs`

  if (store.state.user === null) {
    console.warn('Skipping log action of mutated resource as auth is disabled')
    return true
  }

  try {
    return await ky.post(endpointURL, {
      json: {
        userInfo: {
          username: store.state.user.preferred_username,
          userId: store.state.user.sub
        },
        projectId: store.getters['project/getCurrentProjectId'],
        ...entries
      }
    }).json()
  } catch (e) {
    console.error('Error saving log')
    console.error(e)
  }
}

export async function getLogs (projectId, operation, dayrange, contentType, username, page = 1) {
  let endpointURL = `${logService.uri}/logs?projectId=${projectId}`
  if (operation) endpointURL += `&operation=${operation}`
  if (dayrange) endpointURL += `&dayrange=${dayrange}`
  if (contentType) endpointURL += `&contentType=${contentType}`
  if (username) endpointURL += `&username=${username.toLowerCase()}`

  return await ky.get(endpointURL + `&page=${page}`).json()
}
